import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

import { ReduxAuth } from "./types";

declare global {
  interface Window {
    getAuthData?: () => ReduxAuth;
  }
}

const INITIAL_STATE: ReduxAuth = {
  isLoading: true,
  isAuthenticated: false,
  identity: null,
};

const useReduxAuthImpl = (): ReduxAuth | undefined => {
  const [isLoading, setIsLoading] = useState<ReduxAuth["isLoading"]>(
    INITIAL_STATE.isLoading
  );
  const [isAuthenticated, setIsAuthenticated] = useState<
    ReduxAuth["isAuthenticated"]
  >(INITIAL_STATE.isAuthenticated);
  const [identity, setIdentity] = useState<ReduxAuth["identity"]>(
    INITIAL_STATE.identity
  );

  useEffect(() => {
    const getAuthData = (retry: number) => {
      if (isLoading) {
        const authData = window.getAuthData ? window.getAuthData() : undefined;

        if (authData?.isAuthenticated) {
          setIsLoading(authData.isLoading);
          setIsAuthenticated(authData.isAuthenticated);
          setIdentity(authData.identity);
        } else if (retry < 10) {
          retry++;
          setTimeout(() => getAuthData(retry), retry * 100);
        }
      }
    };

    getAuthData(1);
  }, []);

  return {
    isLoading,
    isAuthenticated,
    identity,
  };
};

export const useReduxAuth = singletonHook(INITIAL_STATE, useReduxAuthImpl);
